import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"
const useStyles = makeStyles(theme => ({
  navbarNotFixed: {
    height: "50px",
    position: "absolute",
  },
  navbarFixed: {
    height: "44px",
    position: "fixed",
  },
}))

const BannerLogin = () => {
  const classes = useStyles()
  const [posScroll, setposScroll] = useState(0)

  const handleScroll = () => {
    let lastScrollY = window.scrollY
    setposScroll(lastScrollY)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [posScroll])

  return (
    <div className="container-banner-login">
      <header
        className={`container-banner-login-navbar ${
          posScroll > 1 ? classes.navbarFixed : classes.navbarNotFixed
        }`}
      >
        <div className="container-banner-login-navbar-button">
          <Link href="https://app.beexconv.com/" target="_blank"
            rel="noopener noreferrer">
            Iniciar Sesión
          </Link>
        </div>
      </header>
    </div>
  )
}
export default BannerLogin
