import React from "react"
import TemplatePageTicker from "@components/pageTicker/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div>
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Software de servicio al cliente con IA para automatizar tu empresa [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/conversations/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Utiliza nuestra plataforma para automatizar todos tus procesos de servicio al cliente de tu empresa. Crea chatbots con IA y workflows para potenciar tu atención."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="💬 Software de servicio al cliente con IA para automatizar tu empresa"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/conversations/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/og-ticker.png`}
      />
      <meta
        property="og:description"
        content="Utiliza nuestra plataforma para automatizar todos tus procesos de servicio al cliente de tu empresa. Crea chatbots con IA y workflows para potenciar tu atención."
      />
    </Helmet>
    <TemplatePageTicker location={location} />
  </div>
)

export default IndexPage
